import request from '@/utils/request'

// 获取赚积分任务列表
export function taskList(forms) {
    return request({
      url: '/MA_task/queryPage',
      method: 'get',
      params:forms
    })
}

// 新增任务主体
export function addTask(forms) {
  return request({
    url: '/MA_task/addTask',
    method: 'post',
    params:forms
  })
}

// 新增视听类任务子项
export function addShiTingInfo(data) {
  return request({
    url: '/MA_task_visual/addVisualTask',
    method: 'post',
    data:data
  })
}

// 新增问卷类任务子项
export function addQuestionInfo(data) {
    return request({
      url: '/MA_task_question/addQuestionTask',
      method: 'post',
      data:data
    })
  }

// 查询任务主题详情
export function taskTypeDetail(form) {
  return request({
    url: '/MA_task/detail',
    method: 'post',
    params:form
  })
} 


// 删除任务
export function removeTask(form) {
  return request({
    url: '/MA_task/deleteTask',
    method: 'post',
    params:form
  })
} 

// 删除任务
export function updateTask(data) {
  return request({
    url: '/MA_task/update',
    method: 'post',
    data:data
  })
} 
// 查看问卷
export function taskanswerList(data) {
  return request({
    url: '/MA_task/taskanswerList',
    method: 'post',
    params:data
  })
} 

//任务上架或下回
export function upDownTask(data) {
  return request({
    url: '/MA_task/upDownActivity',
    method: 'post',
    params:data
  })
} 

//任务提交审核
export function submitCheck(data) {
  return request({
    url: '/MA_task/submit',
    method: 'post',
    params:data
  })
} 

//审核任务  
export function auditTask(data) {
  return request({
    url: '/MA_task/audit',
    method: 'post',
    params:data
  })
} 



