<!--  -->
<template>
	<div class="box">
		<div class='title' style=' padding-top:20px'>
			<el-form style="display:flex;flex-wrap:wrap" :model="searchlist" label-width="150px" ref='searchlist'>
				<!-- v-for循环截取循环的数组-->
				<el-form-item label-width="80px" v-for="(item) in searchType.slice(0,1)" :key='searchType.indexOf(item)'
					:label="item.title">
					<el-select style="width:200px !important" clearable :placeholder="'请输入'+item.title+'查询'"
						v-model='searchlist[item.formName]'>
						<el-option v-for="item in shequList" :label="item.name" :value="item.levelcode"
							:key='item.levelcode'></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label-width="80px" v-for="(item) in searchType.slice(1,2)" :key='searchType.indexOf(item)'
					:label="item.title">
					<el-select clearable style="width:200px !important" :placeholder="'请输入'+item.title+'查询'"
						v-model='searchlist[item.formName]'>
						<el-option label="视听类" value="0"></el-option>
						<el-option label="问卷类" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label-width="10px">
					<div style="display:flex;align-items:center">
						<el-button type='primary' @click='search'>
							<i class="el-icon-search" style="font-size: 14px;color: white;"></i>搜索
						</el-button>
						<el-button type="primary" size="small" @click="exportExcel2">
							<i class="el-icon-share"></i>导出
						</el-button>
					</div>
				</el-form-item>
			</el-form>
		</div>
		<div class="showdatanumber">共查询数据<span>{{ fenye.totalnum }}</span>条</div>
		<div>
			<el-table id='exporttable' :data="tableData" border style="width: 100%;margin-top:20px" stripe>
				
				<el-table-column prop="taskname" show-overflow-tooltip label="任务名称" align="center">
				</el-table-column>
				<el-table-column prop="taskdesc" show-overflow-tooltip label="任务描述" align="center">
				</el-table-column>
				<el-table-column prop="communitynames" show-overflow-tooltip label="社区" align="center">
				</el-table-column>
				
				<el-table-column prop="taskdesc" show-overflow-tooltip label="任务类型" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.tasktype == '0'">试听类</span>
						<span v-if="scope.row.tasktype == '1'">问卷类</span>
					</template>
				</el-table-column>
				<el-table-column prop="effecttime" show-overflow-tooltip label="生效时间" align="center">
				</el-table-column>
				<el-table-column prop="expiretime" show-overflow-tooltip label="结束时间" align="center">
				</el-table-column>
				<el-table-column prop="joinnums" show-overflow-tooltip label="参与人数" align="center">
				</el-table-column>
				
				<el-table-column label="操作" width="200" align="center">
					<div slot-scope="scope" style="display:flex;flex-wrap: wrap;justify-content:center">
						<el-button v-if="scope.row.tasktype == '1'" type="primary" @click="chakan(scope.row)">查看</el-button>
					</div>
				</el-table-column>
				<!-- 不同状态对应操作-->
			</el-table>
		</div>

		<div class='fenyekuang'>
			<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="currentPage"
				:page-sizes="fenye.pagesizes" :page-size="fenye.pagesize"
				layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>
		<el-dialog title="查看" :close-on-click-modal="false" :visible.sync="open" width="1200px" append-to-body>
			<div>
				<el-button type="primary" size="small" @click="exportExcel2">
					<i class="el-icon-share"></i>导出
				</el-button>
			</div>
			<el-table id="dati" :data="answerlist" border style="width: 100%;margin-top:20px" stripe>
				<el-table-column v-for="item in questionlist" :key="item.id" :prop="item.leixing" show-overflow-tooltip :label="item.questiontext" align="center">
				</el-table-column>
				<!-- 不同状态对应操作-->
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	import {
		taskList,
		removeTask,
		taskanswerList
	} from '@/api/renwulist/renwu.js'
	import {
		isShequ
	} from '@/api/shequ/shequ.js'
	import FileSaver from 'file-saver'
	import XLSX from 'xlsx'
	export default {
		components: {},
		mounted() {
			isShequ({
				userid: this.GLOBAL.adminId()
			}).then(res => {
				if (res.result == 200) {
					this.shequList = res.detail
					this.getList()
				} else {
					this.$message.error(res.description)
				}
			})
		},
		data() {
			return {
				answerlist:[],
				questionlist:[],
				open:false,
				form:{},
				question:[],
				shequList: [], //社区列表
				xiaoquList: [], //小区列表
				tableData: [], //获取的表格数据
				formDialog: false, //弹出框
				formtype: [], //表单须填数据模板 
				searchlist: {
					shequ: '',
					renwuType: '',
				}, //搜索数据
				searchType: [{
						title: '社区',
						formName: 'shequ'
					},
					{
						title: '任务类型',
						formName: 'renwuType'
					},
				], //搜索框成员
				currentPage: 1, //当前页
				fenye: {
					pagesizes: [10, 20, 50],
					pagesize: 10,
					totalnum: 0
				}, //分页数据
				accountUuid: '', //账户uuid
			}
		},
		methods: {
			/* getanswer(answer){
				console.log('answer',answer)
				var daanlist = [];
				for(var item of answer){
					if(item == ''){
						daanlist.push(item);
					}else{
						var a = item.split('^')[0].split('');
						var b = '';
						for(var i = 0;i<a.length;i++){
							if(i != 0 && i != a.length-1){
								b = b + a[i];
							}
						}
						daanlist.push(b);
					}
				}
				var target = {};
				for(var k = 0;k<daanlist.length;k++){
					var shuju = {
						['question'+k]:daanlist[k]
					}
					Object.assign(target,shuju); 
				}
				return target;
			}, */
			chakan(a) {
				this.answerlist = []
				taskanswerList({
					userid: this.GLOBAL.adminId(),
					taskid: a.uuid,
				}).then(res => {
					if (res.result == 200) {
						if(res.detail.answerlist.length != 0){
							for(let item of res.detail.answerlist){
								let daan =  item.answer.split('^,');
								let answerlist = [];
								daan.map((i,idx)=>{
									let oneAnswer = {
										[`question${idx}`]: i
									}
									Object.assign(answerlist,oneAnswer); 
								})
								this.answerlist.push(answerlist);
							}
						}
						let question = res.detail.question;
						for(let i = 0;i<question.length;i++){
							question[i].leixing = 'question' + i;
						}
						
						this.questionlist = question;
						this.open = true;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			search() {
				this.currentPage = 1
				this.getList()
			}, //搜索请求
			sizeChange(val) {
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList()
			}, //展示条数改变时
			currentChange(val) {
				this.currentPage = val;
				this.getList()
			}, //当前页改变时
			getList() {
				let arealevelArr = []
				if (this.searchlist.shequ == '') {
					this.shequList.map(item => {
						arealevelArr.push(item.levelcode)
					})
				} else {
					arealevelArr = [this.searchlist.shequ];
				}
				taskList({
					userid: this.GLOBAL.adminId(),
					communitycodes: arealevelArr.join(','),
					tasktype:this.searchlist.renwuType,
					pageSize: this.fenye.pagesize,
					pageNum: this.currentPage,
				}).then(res => {
					if (res.result == 200) {
						console.log(res)
						this.tableData = res.detail.list
						this.tableData.map((item, index) => {
							item.xuhao = index + 1
						})
						this.fenye.totalnum = res.detail.totalRow
					} else {
						this.$message.error(res.description);
					}
				})
			}, //获取表格数据和查询请求
			remove(row) {
				this.$confirm('确定删除该任务吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					removeTask({
						userid: this.GLOBAL.adminId(),
						taskid: row.uuid
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('删除成功')
							this.getList()
						} else {
							this.$message.error(res.description)
						}
					})
				})
			}, //删除任务
			exportExcel() {
				let xlsxParam = {
					raw: true
				};
				var wb = XLSX.utils.table_to_book(document.querySelector("#exporttable"), xlsxParam)
				var wbout = XLSX.write(wb, {
					bookType: 'xlsx',
					bookSST: true,
					type: 'array'
				})
				try {
					FileSaver.saveAs(new Blob([wbout], {
						type: 'application/octet-stream'
					}), '问卷填报人员.xlsx')
				} catch (e) {
					if (typeof console !== 'undefined') console.log(e, wbout)
				}
				return wbout
			}, //导出数据
			exportExcel2() {
				let xlsxParam = {
					raw: true
				};
				var wb = XLSX.utils.table_to_book(document.querySelector("#dati"), xlsxParam)
				var wbout = XLSX.write(wb, {
					bookType: 'xlsx',
					bookSST: true,
					type: 'array'
				})
				try {
					FileSaver.saveAs(new Blob([wbout], {
						type: 'application/octet-stream'
					}), '问卷.xlsx')
				} catch (e) {
					if (typeof console !== 'undefined') console.log(e, wbout)
				}
				return wbout
			}, //导出数据
		},
	}
</script>
<style lang='scss'>
	.showdatanumber {
		margin: 0 10px 10px;
		color: #409EFF;

		span {
			margin: 0 5px;
			font-size: 20px;
		}
	}

	.box {
		margin: 10px;
		position: relative;
		z-index: 1;

		.el-table th,
		.el-table tr {
			background-color: transparent;
		}

		.el-table {
			background-color: transparent;
		}
	}
</style>
<style scoped>
	.playBtn {
		padding: 5px !important;
		margin-left: 4px !important;
	}

	.title {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.fenyekuang {
		width: 100%;
		height: 50px;
		margin-top: 1px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
</style>
